import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import PrivateRoute from "./components/private-router";
import ApiTokensPage from "./pages/developers/api-tokens.page";
import PlaygroundPage from "./pages/developers/playground.page";
import WebhookPage from "./pages/developers/webhook.page";
import SeoComponent from "./components/SEO/seo";

import LoginPage from "./pages/login.page";
import LogsPage from "./pages/logs/logs.page";
import SessionsPage from "./pages/logs/session.page";
import ChargeStationsPage from "./pages/network/charge-stations.page";
import LocationPage from "./pages/network/location.page";
// import SignUpPage from "./pages/sign-up.page";
// import RatesPage from "./pages/network/rates.page";""""
import AccountSettingsPage from "./pages/users/account-settings.page";
import UserSettingsPage from "./pages/users/user-settings.page";
import TermPolicy from "./pages/term-policy/term-policy.page";
import ForgotPasswordPage from "./pages/forgot-password.page";
import ResetPasswordPage from "./pages/reset-password.page";
import ChargerHistoryPage from "./pages/logs/charge-history.page";

function App() {
  return (
    <>
      <div>
        <SeoComponent
          title="Empowering Your Charger Management"
          description="Take charge of your EV charging network with MadEV - the ultimate management system. Add new chargers and manage their status effortlessly"
        />
      </div>
      <Routes>
        <Route path="*" element={<Navigate to="/network/location" replace />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/password" element={<Outlet />} >
          <Route path="forgot" element={<ForgotPasswordPage />} />
          <Route path="reset/:token" element={<ResetPasswordPage />} />
          {/* <Route path="error" element={<ErrorPasswordPage />} /> */}
        </Route>

        {/* <Route path="/sign-up" element={<SignUpPage />} /> */}
        <Route path="/network" element={<PrivateRoute />}>
          <Route path="location" element={<LocationPage />} />
          <Route path="charge-stations" element={<ChargeStationsPage />} />
          {/* <Route path="rates" element={<RatesPage />} /> */}
        </Route>

        <Route path="/logs" element={<PrivateRoute />}>
          <Route path="ocpp-logs" element={<LogsPage />} />
          <Route path="sessions" element={<SessionsPage />} />
          <Route path="charger-history" element={<ChargerHistoryPage />} />
        </Route>

        <Route path="/users" element={<PrivateRoute />}>
          <Route path="user-settings" element={<UserSettingsPage />} />
          <Route path="account-details" element={<AccountSettingsPage />} />
        </Route>

        <Route path="/developers" element={<PrivateRoute />}>
          <Route path="api-tokens" element={<ApiTokensPage />} />
          <Route path="playground" element={<PlaygroundPage />} />
          <Route path="webhooks" element={<WebhookPage />} />
        </Route>

        <Route path="/term-policy/:param" element={<TermPolicy />}></Route>
      </Routes>
    </>
  );
}

export default App;
